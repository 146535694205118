import React from 'react';

import { InView } from 'react-intersection-observer';

import './heading.scss';

const Heading = ( { string } ) => {

    const renderWords = () => {
        return string.split( ' ' ).map( ( word, index ) => {
            return <span key={index}><span>{word}{' '}</span></span>;
        } );
    };

    return <InView>
        {( { inView, ref } ) => (
            <h1 className={`heading ${inView ? 'is-inview' : ''}`} ref={ref}>
                {renderWords()}
            </h1> )}
    </InView>;

};

export default Heading;
