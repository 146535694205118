import { useStaticQuery, graphql } from 'gatsby';

export const useData = () => {

    const query = useStaticQuery( graphql`query {
        allContentfulPost(
            sort: { order: ASC, fields: [order] }
        ) {
            edges {
                node {
                    id
                    title
                    shortDescription
                    slug
                    thumbnail {
                        title
                        gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, width: 400)
                    }
                }
            }
        }
        allContentfulPage(
            sort: { order: ASC, fields: [title] }
        ) {
            edges {
                node {
                    id
                    title
                    slug
                }
            }
        }
    }` );

    const posts = query.allContentfulPost.edges.map( node => { 
        node.node.type = 'post';
        return node.node; 
    } );

    const pages = query.allContentfulPage.edges.map( node => {
        node.node.type = 'page';
        return node.node;
    } );

    return { posts, pages };

};
