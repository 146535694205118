import React from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import './attachments.scss';

const Attachments = ( { attachments } ) => {

    const availableColumnSizes = {
        '0': 'column-0',
        '38.2': 'column-38',
        '50': 'column-50',
        '61.8': 'column-62',
        '100': 'column-100'
    };


    const renderBodyOrMedia = ( column, attachment ) => {

        const { alignMedia } = attachment;

        if ( column === 'left' ) {
            if ( ( alignMedia === 'Left' || alignMedia === null ) ) {
                return renderImageOrVideo( attachment );
            } else {
                return renderBody( attachment );
            }
        }
        if ( column === 'right' ) {
            if ( alignMedia === 'Right' ) {
                return renderImageOrVideo( attachment );
            } else {
                return renderBody( attachment );
            }
        }
    };

    const renderBody = ( attachment ) => {

        const { title, showTitle, description } = attachment;

        return <>
            {title && showTitle && <h2>{title}</h2>}
            <div dangerouslySetInnerHTML={{
                __html: ( description && description.childMarkdownRemark ) ? description.childMarkdownRemark.html : ''
            }}></div>
        </>;
    };

    const renderImageOrVideo = ( attachment ) => {

        const { image, media, autoplayMedia, loopMedia, muteMedia } = attachment;
        const { file } = media || {};

        if ( image ) {
            return <GatsbyImage image={image} alt={'title'} />;
        } else if ( file && file.contentType === 'video/mp4' ) {
            return <video width="320" height="240" controls autoPlay={autoplayMedia} loop={loopMedia} muted={muteMedia}>
                <source src={file.url} type="video/mp4" />
                Your browser does not support the video tag.
            </video>;
        }
    };

    const renderAttachments = () => {

        if ( attachments ) {
            return attachments.map( ( attachment, index ) => {

                let { media, columnSizes } = attachment;

                if ( columnSizes === null ) {
                    columnSizes = '61.8 / 38.2';
                }

                if ( typeof columnSizes === 'string' || columnSizes instanceof String ) {
                    columnSizes = columnSizes.split( ' / ' );
                }

                const image = getImage( media );

                attachment.columnSizes = columnSizes;
                attachment.image = image;

                return <section key={index} className="attachment">
                    <section className={`${availableColumnSizes[columnSizes[0]]}`}>
                        {renderBodyOrMedia( 'left', attachment )}
                    </section>
                    <section className={`${availableColumnSizes[columnSizes[1]]}`}>
                        {renderBodyOrMedia( 'right', attachment )}
                    </section>
                </section>;

            } );
        }
    };

    return renderAttachments() || null;

};

export default Attachments;
