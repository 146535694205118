import React, { useEffect, useContext } from 'react';

import Attachments from '../components/attachments';
import ListItem from '../components/list-item';
import Heading from '../components/heading';
import SEO from '../components/seo';

import { LayoutContext } from '../components/layout';
import { graphql } from 'gatsby';
import { useData } from '../data/data';

import './post.scss';

const Post = ( { data } ) => {

    const { posts } = useData();
    const layoutContext = useContext( LayoutContext );

    const { title, body, attachments, slug } = data.contentfulPost;

    useEffect( () => {
        layoutContext.setResetScroll( true );
    }, [] );

    const renderNextPost = () => {

        const currentIndex = posts.findIndex( p => p.slug === slug );
        const nextIndex = ( currentIndex + 1 ) % posts.length;
        const nextPost = posts[nextIndex];

        return <ListItem data={{ ...nextPost, type: 'compact' }} />;

    };

    return <main className="post">
        <SEO title={title} description={( body && body.childMarkdownRemark ) ? body.childMarkdownRemark.html : undefined} />
        <header>
            <section className="title">
                <Heading string={title} />
                <a href="#" onClick={() => { history.back(); }}>Back</a>
            </section>
            <section className="description">
                <div dangerouslySetInnerHTML={{
                    __html: ( body && body.childMarkdownRemark ) ? body.childMarkdownRemark.html : ''
                }}></div>
            </section>
        </header>
        <Attachments attachments={attachments}/>
        <section className="next">
            <p>Next recommended article:</p>
            <ul className="list">
                {renderNextPost()}
            </ul>
        </section>
    </main>;

};

export default Post;
export const pageQuery = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      attachments {
        alignMedia
        autoplayMedia
        loopMedia
        muteMedia
        columnSizes
        title
        showTitle
        description {
          childMarkdownRemark {
            html
          }
        }
        media {
          gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR, formats: [WEBP], width: 1000)
          file {
            contentType
            url
          }
        }
      }
    }
  }
`;